@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

@import '~react-agenda/build/styles.css';
@import '~react-datetime/css/react-datetime.css';


body {
    font-family:'Quicksand', sans-serif;
    height: 100vh;
    width: 100vw;
    
}

.link {
    color: black;
}

.link :hover {
    color:black;
    text-shadow: 0.1em 0.1em 0.2em grey;
}


.titre::before, .titre::after { 
    content:''; 
    display:inline-block; 
    vertical-align: middle; 
    width: 5rem; 
    height: 1px; 
    background: #000 }

.titre::before { 
    margin-right: 1rem 
}

.titre::after { 
    margin-left: 1rem 
}


.scroll_to_top { 
    position: fixed; 
    width: 25px; 
    height: 25px; 
    bottom: 50px; 
    right: 30px; 
  } 
  .scroll_to_top img { 
    width: 25px; 
  }


  .buttonExcel {
      background-color: green;
      color: white;
      border-radius: 5px;
      margin: 40px;
      border: none;
      width: 110px;
      height: 30px;
      font-size: 14px;
      font-family: 'Quicksand', sans-serif;
      font-weight: bolder;
  }

  .buttonExcelAll {
    background-color: green;
    color: white;
    border-radius: 5px;
    margin-bottom: 30px;
    margin-top: 20px;
    border: none;
    width: 110px;
    height: 30px;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
}




.photo {
    display: table;
}
.photo a {
    vertical-align: middle;
    display: table-cell;
}
.photo img {
    display: block;
    margin-bottom: 120px;
    margin-right: 20px;
}

.contentModal {
    margin-Top: 10px;
    margin-Bottom: 100px;
}